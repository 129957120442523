import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AddCoordinate from "../components/AddCoordinate";
import GetCoordinate from "../components/GetCoordinate";
import QuestionForm from "../components/QuestionForm";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import L from "leaflet";
import LeafLet from "../components/LeafLet";
import {Helmet} from "react-helmet-async";

const Coordinate = () => {

    const { t } = useTranslation();

    const lang = useSelector((state) => state.language);

    const navigate = useNavigate();
    const [reload, setReload ] = useState(0);
    const [ lat, setLatitude ] = useState(0);
    const [ lng , setLongitude] = useState(0);
    const [ position, setPosition ] = useState(null);
    const [coordinate, setCoordinate ] = useState(0);

    useEffect(() => {
        if(position) {
            setLatitude(position.lat);
            setLongitude(position.lng);
        }
    }, [position])

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);

                    //navigate( '/' + lang + `/map&lat=${latitude}&long=${longitude}`);

                },
                (err) => {

                }
            );
        } else {

        }
    };

    const application_type = process.env.REACT_APP_TYPE;
    return(
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>
            <div className="page coordinate">

                <GetCoordinate reload={reload} setReload={setReload} setCoordinate={setCoordinate}></GetCoordinate>
                { coordinate < 10 && <AddCoordinate reload={reload} setReload={setReload} lat={lat} lng={lng}></AddCoordinate>}
                <LeafLet setPosition={setPosition}></LeafLet>
                <QuestionForm isCaptcha={false}></QuestionForm>
            </div>
        </>)
}
export default Coordinate;